import React from "react";
import { ProductCategory } from "@/common/entities/product/ProductCategory";
import style from "@/components/SearchProducts/searchProducts.module.scss";

interface SearchProductsTitleAndCategoryProps {
    title: string;
    productCategories?: ProductCategory[];
    productCode: string;
    categoryName: string;
}

const SearchProductsTitleAndCategory = ({ title, productCode, categoryName }: SearchProductsTitleAndCategoryProps) => {
    return (
        <div className="d-flex flex-column ms-2">
            <b className={style.productName}>{title}</b>
            <span className={style.productCategory}>{categoryName}</span>
            <span className={style.productReference}>{productCode}</span>
        </div>
    );
};

export default SearchProductsTitleAndCategory;
