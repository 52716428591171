import { ProductCategory } from "@/common/entities/product/ProductCategory";
import { DefaultImage, Products, ProductType } from "../entities/product/Product";
import { ProductImage } from "../entities/product/ProductImage";
import { extractNumber } from "../helpers/util.functions";
import EcoIcon from "@/images/eco-design.svg";
import ProIcon from "@/images/pro-icon.png";
import APPIcon from "@/images/energyclass/a++_arrow.png";
import APIcon from "@/images/energyclass/a+_arrow.png";
import AIcon from "@/images/energyclass/a_arrow.png";
import BIcon from "@/images/energyclass/b_arrow.png";
import CIcon from "@/images/energyclass/c_arrow.png";
import DIcon from "@/images/energyclass/d_arrow.png";
import { ProductsPacks } from "../entities/product/SimilarProducts";

export enum ProductImageSizeType {
    small = "small",
    medium = "medium",
    high = "high",
    original = "original",
}

class ProductsUtils {
    public static getProductCategoryLink = (productCategories?: ProductCategory[]) => {
        if (!productCategories || !productCategories.length) {
            return { name: "", url: "/" };
        }

        let defaultCategoryUrl = "/";
        let defaultCategoryName = "";
        const defaultCategory = productCategories.find((prodCategory) => prodCategory.default && prodCategory.category);

        if (defaultCategory) {
            defaultCategoryName = defaultCategory.category.sitesCategories[0]?.name;
            defaultCategoryUrl = defaultCategory.category.sitesCategories.length ? defaultCategory.category.sitesCategories[0].redirectUrl : "/";
        } else if (productCategories.length) {
            defaultCategoryName = productCategories[0].category.sitesCategories[0].name;
            defaultCategoryUrl = productCategories[0].category.sitesCategories.length ? productCategories[0].category.sitesCategories[0].redirectUrl : "/";
        }

        return { name: defaultCategoryName, url: defaultCategoryUrl };
    };

    public static getProductLink = (url: string, productCategories?: ProductCategory[]) => {
        if (!productCategories || !productCategories.length) {
            return "";
        }

        return "/" + ProductsUtils.getProductCategoryLink(productCategories).url + "/" + url;
    };

    public static getProductCoverImage = (size: ProductImageSizeType, image?: DefaultImage) => {
        if (!image) {
            return undefined;
        }

        switch (size) {
            case ProductImageSizeType.original:
                return image?.image?.originalFileName;
            case ProductImageSizeType.small:
                return image.image?.smallSizeFileName ?? image?.image?.originalFileName;
            case ProductImageSizeType.medium:
                return image.image?.mediumSizeFileName ?? image?.image?.originalFileName;
            case ProductImageSizeType.high:
                return image.image?.highSizeFileName ?? image?.image?.originalFileName;
            default:
                return image?.image?.originalFileName;
        }
    };

    public static getProductCoverImageFromArray = (size: ProductImageSizeType, images?: ProductImage[]) => {
        if (!images) {
            return undefined;
        }

        let coverImage = images.find((image) => image.cover && image.image);
        if (!coverImage) {
            if (images.length) {
                coverImage = images[0];
            } else {
                return undefined;
            }
        }

        switch (size) {
            case ProductImageSizeType.original:
                return coverImage?.image?.originalFileName;
            case ProductImageSizeType.small:
                return coverImage?.image?.smallSizeFileName ?? coverImage?.image?.originalFileName;
            case ProductImageSizeType.medium:
                return coverImage?.image?.mediumSizeFileName ?? coverImage?.image?.originalFileName;
            case ProductImageSizeType.high:
                return coverImage?.image?.highSizeFileName ?? coverImage?.image?.originalFileName;
            default:
                return coverImage?.image?.originalFileName;
        }
    };

    public static getProductLabels = (productDetails: Products) => {
        const labels: { warranty?: number; pro?: string; ecoDesign?: string; energyClass?: string } = {};

        const warranty = productDetails?.attributesCharacteristics?.find((attribute) => attribute?.attribute?.name === "Garantie");
        labels.warranty = warranty ? extractNumber(warranty.name) : undefined;

        const proCategory = productDetails?.categories?.find(
            (category) => category.default === true && ["Sobe PRO", "Focare Seminee PRO", "Seminee Modulare PRO"].includes(category.category.name),
        );
        labels.pro = proCategory ? ProIcon.src : "";

        const ecoDesign = productDetails?.attributesCharacteristics?.find((attribute) => attribute?.attribute?.name === "Norma");
        labels.ecoDesign = ecoDesign?.name == "Eco Design" ? EcoIcon.src : "";

        const energyClassAttr = productDetails?.attributesCharacteristics?.find((attribute) => attribute?.attribute?.name === "Clasa Energetica");
        let energyClass = "";

        if (energyClassAttr) {
            switch (energyClassAttr.name) {
                case "A++":
                    energyClass = APPIcon.src;
                    break;
                case "A+":
                    energyClass = APIcon.src;
                    break;
                case "A":
                    energyClass = AIcon.src;
                    break;
                case "B":
                    energyClass = BIcon.src;
                    break;
                case "C":
                    energyClass = CIcon.src;
                    break;
                case "D":
                    energyClass = DIcon.src;
                    break;

                default:
                    break;
            }
        }
        labels.energyClass = energyClass;

        return labels;
    };

    public static checkIfPackProductsHavePrice = (pack: Products) => {
        if (pack.type !== ProductType.pack) return false;

        const findNoPriceProduct = pack?.packedProducts.find((item: ProductsPacks) => item.packedProduct.priceWithVat == 0);
        if (findNoPriceProduct) return false;

        return true;
    };
}

export default ProductsUtils;
