import { backendUrl, domainUrl } from "@/lib/Constants";
import { ProductFiles, Products } from "@/common/entities/product/Product";
import { doGet } from "@/common/helpers/fetch.helpers";
import { GroupedAttributesFilters } from "@/common/entities/product/Attributes";
import { ProductImage } from "@/common/entities/product/ProductImage";
import { SitesCategories, SitesCategoriesFilterResponse } from "@/common/entities/sites/SiteCategories";

export interface ProductsFilter {
    from: number;
    size: number;
    search?: string;
    productsIds?: number[];
    characteristics?: string;
    intervals?: string;
    siteCategoriesIds?: string;
    categoryName?: string;
    siteCategoryUrl?: string;
    isAtPromotion?: boolean;
    selectRandom?: boolean;
    orderBy?: string;
    orderDirection?: string;
    brandId?: number;
    isOutlet?: boolean;
    siteCategoryParentId?: number;
}

export interface ProductsGroupedAttributesFilter {
    subCategoryUrl?: string;
    selectedCharacteristics?: string;
    selectedIntervals?: string;
    siteCategoryParentId?: string;
    isAtPromotion?: boolean;
    brandId?: string;
}

export interface ProductsPaginatedResponse {
    data: Products[];
    total: number;
}

export async function getProductDetailsBySlug(slug: string): Promise<Products | undefined> {
    const params: Record<string, string> = {
        siteDomain: domainUrl,
    };

    return doGet(`${backendUrl}/products/public/${slug}`, params);
}

export async function getProducts(filter: ProductsFilter): Promise<ProductsPaginatedResponse> {
    const filterParams: URLSearchParams = new URLSearchParams();
    filterParams.set("size", filter.size.toString());
    filterParams.set("from", filter.from.toString());

    if (filter.search) {
        filterParams.set("search", filter.search);
    }

    if (domainUrl) {
        filterParams.set("siteDomain", domainUrl);
    }

    if (filter.productsIds && filter.productsIds.length) {
        filterParams.set("productsIds", filter.productsIds?.join(","));
    }

    if (filter.siteCategoriesIds && filter.siteCategoriesIds.length) {
        filterParams.set("siteCategoriesIds", filter.siteCategoriesIds);
    }

    if (filter.siteCategoryParentId) {
        filterParams.set("siteCategoryParentId", filter.siteCategoryParentId.toString());
    }

    if (filter.brandId) {
        filterParams.set("brandId", filter.brandId.toString());
    }

    if (filter.characteristics) {
        filterParams.set("characteristicsIds", filter.characteristics);
    }

    if (filter.intervals) {
        filterParams.set("intervalsIds", filter.intervals);
    }

    if (filter.categoryName) {
        filterParams.set("categoryName", filter.categoryName);
    }

    if (filter.siteCategoryUrl) {
        filterParams.set("siteCategoryUrl", filter.siteCategoryUrl);
    }

    if (filter.isAtPromotion) {
        filterParams.set("isAtPromotion", filter.isAtPromotion.toString());
    }

    if (filter.selectRandom) {
        filterParams.set("selectRandom", filter.selectRandom.toString());
    }

    if (filter.orderBy) {
        filterParams.set("orderBy", filter.orderBy);
    }

    if (filter.orderDirection) {
        filterParams.set("orderDirection", filter.orderDirection);
    }

    return doGet(`${backendUrl}/products/all?${filterParams.toString()}`);
}

export async function getProductsGroupedAttributes(filter: ProductsGroupedAttributesFilter): Promise<GroupedAttributesFilters[]> {
    const params = new URLSearchParams({
        siteDomain: domainUrl || "",
        siteCategorySlug: filter.brandId ? "" : filter.subCategoryUrl || "",
        brandSlug: filter.brandId ? filter.subCategoryUrl || "" : "",
        characteristicsIds: filter.selectedCharacteristics || "",
        intervalsIds: filter.selectedIntervals || "",
        siteCategoryParentId: filter.siteCategoryParentId || "",
        brandId: filter.brandId || "",
    });

    if (filter.isAtPromotion) {
        params.set("isAtPromotion", "true");
    }

    return doGet(`${backendUrl}/products-attributes-characteristics/filters?${params.toString()}`);
}

export async function getSiteCategoriesPromotionsProducts(filter: any): Promise<SitesCategoriesFilterResponse[]> {
    const params = new URLSearchParams({
        siteDomain: domainUrl || "",
        characteristicsIds: filter.characteristicsIds || "",
    });

    if (filter.isAtPromotion) {
        params.set("isAtPromotion", "true");
    }
    return doGet<SitesCategoriesFilterResponse[]>(`${backendUrl}/sites/sites-categories/filter?${params.toString()}`);
}

export async function getSiteMainCategories(): Promise<SitesCategories[]> {
    return doGet<SitesCategories[]>(`${backendUrl}/sites/${domainUrl}/main-categories`);
}

export async function getProductFilesProductsById(slug: string): Promise<ProductFiles | undefined> {
    return doGet(`${backendUrl}/products/${slug}/public-files`);
}

export async function getProductImagesBySlug(slug: string): Promise<ProductImage[] | undefined> {
    return doGet(`${backendUrl}/products/public/${slug}/images`);
}
