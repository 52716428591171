"use client";

import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./floatingCart.module.scss";
import { FaAngleRight } from "react-icons/fa";
import { CartItem } from "@/common/entities/cart/CartItem";
import { useRouter } from "next/navigation";
import { formatNumberWithDecimals } from "@/common/helpers/util.functions";
import { ProductType } from "@/common/entities/product/Product";
import { ImageWrapper } from "@/common/components/Images/ImageWrapper";
import ProductsUtils, { ProductImageSizeType } from "@/common/utils/ProductsUtils";
import { CheckoutCart, CheckoutCartConfigurator } from "@/components/Cart/CartForm/CartForm";
import CopyText from "@/common/components/CopyText/CopyText";
import { getConfiguratorImage } from "@/common/helpers/fetch.helpers";

export interface FloatingCartData {
    cart: CheckoutCart | null;
}

const FloatingCart = ({ cart }: FloatingCartData) => {
    const router = useRouter();

    const navigate = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (cart && cart.totalCostWithVat === 0) {
            e.preventDefault();
        } else {
            router.push("/cart");
        }
    };

    const calculateUnitPrice = (item: CartItem) => {
        if ([ProductType.glass, ProductType.door].includes(item.product.type) && item.height && item.length) {
            const areaInSquareMeters = (Number(item.height) * Number(item.length)) / 1000000;
            return formatNumberWithDecimals(
                (Number(item.specialPriceWithVat) ? Number(item.specialPriceWithVat) : Number(item.priceWithVat)) * areaInSquareMeters,
            );
        }
        return formatNumberWithDecimals(item.specialPriceWithVat ? item.specialPriceWithVat : item.priceWithVat);
    };

    const calculateConfiguratorUnitPrice = (configurator: CheckoutCartConfigurator) => {
        let totalPriceConfigurator = 0;
        for (const cartProduct of configurator.cartProducts) {
            totalPriceConfigurator +=
                Number(cartProduct.specialPriceWithVat) !== 0 ? Number(cartProduct.specialPriceWithVat) : Number(cartProduct.priceWithVat);
        }

        return totalPriceConfigurator;
    };

    const calculateTotalQuantity = () => {
        return (
            cart?.cartProducts?.reduce((total, item) => {
                const quantity = [ProductType.basic, ProductType.pack].includes(item.product.type) ? Number(item.quantity) : 1;
                return total + quantity;
            }, 0) ?? 0
        );
    };

    return (
        <Container className={styles.floatingCart}>
            <h4 className="my-2 d-flex align-items-center justify-content-between">
                <div>Cosul meu</div>
                {cart?.id && (
                    <>
                        <small>
                            ID: {cart?.id}
                            <CopyText className={styles.copyCart} text={cart?.id.toString()} />
                        </small>
                    </>
                )}
            </h4>

            <div className={styles.productsWrapper}>
                {cart?.cartProducts &&
                    cart?.cartProducts.map((item, index) => (
                        <Row key={index} className={`${styles.productRow} g-0`}>
                            <Col md={2}>
                                <ImageWrapper
                                    width={80}
                                    height={80}
                                    url={ProductsUtils.getProductCoverImageFromArray(ProductImageSizeType.small, item.product?.productImages)}
                                    title={item.product.name}
                                    className="p-1"
                                />
                            </Col>
                            <Col md={4}>
                                <span className={styles.productName}>{item.product.name}</span>
                            </Col>
                            <Col md={2}>
                                <div className={styles.productQuantity}>
                                    {[ProductType.basic, ProductType.pack].includes(item.product.type) ? (
                                        <span>X {Number(item.quantity).toFixed(0)}</span>
                                    ) : (
                                        <span>X 1</span>
                                    )}
                                </div>
                            </Col>
                            <Col md={4}>
                                <span className={styles.productPrice}>{calculateUnitPrice(item)} Lei</span>
                            </Col>
                        </Row>
                    ))}

                {cart?.configurators &&
                    cart?.configurators.map((item, index) => (
                        <Row key={index} className={`${styles.productRow} g-0`}>
                            <Col md={2}>
                                <ImageWrapper
                                    width={80}
                                    height={80}
                                    url={getConfiguratorImage(item.configurator)}
                                    title={item.configuratorName}
                                    className="p-1"
                                />
                            </Col>
                            <Col md={4}>
                                <span className={styles.productName}>{item.configuratorName}</span>
                            </Col>
                            <Col md={2}>
                                <div className={styles.productQuantity}>
                                    <span>X 1</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <span className={styles.productPrice}>{calculateConfiguratorUnitPrice(item)} Lei</span>
                            </Col>
                        </Row>
                    ))}

                {!cart && <p className="mt-2 mb-2 text-center">Niciun produs selectat.</p>}
                {cart?.cartProducts?.length === 0 && cart?.configurators?.length === 0 && <p className="mt-2 mb-2 text-center">Nu ai adaugat niciun produs.</p>}
            </div>
            {cart && (cart?.cartProducts?.length > 0 || cart?.configurators?.length > 0) && (
                <>
                    <div className="d-flex justify-content-between align-items-center p-3 pb-0">
                        <span>
                            Pret total{" "}
                            <small>
                                ({calculateTotalQuantity()}
                                {calculateTotalQuantity()} {calculateTotalQuantity() === 1 ? "produs" : "produse"}){" "}
                            </small>
                        </span>
                        <span className={styles.totalPriceCart}>{formatNumberWithDecimals(Number(cart?.totalCostWithVat ?? 0)) ?? 0} LEI</span>
                    </div>

                    <div className="p-3 pt-2 mt-1">
                        <Button className={styles.finalizeButton} onClick={navigate}>
                            <span>Spre finalizare comanda</span>
                            <FaAngleRight />
                        </Button>
                    </div>
                </>
            )}
        </Container>
    );
};

export default FloatingCart;
