"use client";
import React from "react";
import { Badge } from "react-bootstrap";
import styles from "@/components/layout/navbar/topNavbar.module.scss";
import FloatingCart from "@/components/FloatingCart/page";
import { FiShoppingCart } from "react-icons/fi";
import { CheckoutCart } from "@/components/Cart/CartForm/CartForm";

interface NavbarCartButtonProps {
    showCartDetails: boolean;
    cart: CheckoutCart | null;
    totalProducts: number;
}

const NavbarCartButton = ({ cart, totalProducts = 0, showCartDetails }: NavbarCartButtonProps) => {
    return (
        <>
            <FiShoppingCart className="me-2" /> Cosul meu
            {totalProducts > 0 && (
                <Badge pill bg="danger" className={styles.badge} style={{ position: "absolute", top: "5px", right: "20px" }}>
                    {Number(totalProducts).toFixed(0)}
                </Badge>
            )}
            {showCartDetails && (
                <div className={styles.additionalComponent}>
                    <FloatingCart cart={cart} />
                </div>
            )}
        </>
    );
};

export default NavbarCartButton;
